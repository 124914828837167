<template>
 <div>
   <list-template
       :table-config="tableConfig"
       :table-data="tableData"
       :total="total"
       :current-page="page"
       :loading="loading"
       has-add
       @onSearch="onSearch"
       @onReset="search = null"
       @onAdd="$router.push('./add')"
       @onChangePage="changeCurrentPage"
       @onHandle="tableHandle"
       :search-config="searchConfig"
       @onBatchImport="dialogVisible=true"
   ></list-template>
   <el-dialog :visible.sync="dialogVisible" title="批量导入">
     <div style="color: #000;font-weight: 600;margin-bottom: 24px">
       <div style="margin-top: 12px">注意：导入时校区名称，教学楼名称应与系统内完全一致</div>
     </div>
     <div>
       <span style="display: inline-block;width: 180rem">下载模板</span>
       <el-button size="small" @click="onDownload">点此下载模板</el-button>
     </div>
     <div style="margin: 24rem 0;display: flex;align-items: center">
       <span style="display: inline-block;width: 180rem">上传文件</span>
       <el-upload :action="$_axios2.defaults.baseURL + '/api/base/classroom-import'" :before-upload="beforeUpload" :headers="{ authorization: 'Bearer ' + $store.getters.token }"
                  :on-success="onSuccess" :show-file-list="false" accept=".xlsx,.xls" class="upload-demo" name="file" style="width: 100rem">
         <el-button size="small" type="primary">点此上传文件</el-button>
       </el-upload>
     </div>
   </el-dialog>

 </div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {classroomDel} from '@/api/school'
import {mapState} from "vuex"
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
		return {
      loading:true,
			tableData: [],
			total: 0,
      dialogVisible: false,
      tableConfig:[
        {
          prop:"classroom_name",
          label:"教室名称"
        },
        {
          prop:"school_name",
          label:"所属校区"
        },
        {
          prop:"max_number",
          label:"最大容纳人数",
          width:120
        },
        {
          prop:"dimicile_buliding_name",
          label:"所属教学楼"
        },
        {
          prop:"storey",
          label:"楼层",
          width:80
        },
        {
          handle:true,
          width:150,
          label:"操作",
          buttons: [{ type:"edit", text:"编辑" },{ type:"delete", text:"删除" }]
        },
      ],
      searchConfig: [
        {
          prop: 'classroom_name',
          placeholder: '搜索教室名称'
        },
        {
          prop: 'school_id',
          tag: 'select',
          placeholder: '筛选校区',
          options: [],
          label: 'school_name',
          value: 'id'
        },
        {
          prop: 'buliding_id',
          tag: 'select',
          placeholder: '筛选教学楼',
          options: [],
          label: 'dimicile_buliding_name',
          value: 'id'
        },
      ],
		}
	},
  computed:{
    ...mapState(["page"])
  },
	methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage",e);
      this.getData();
    },
		getData() {
			let page = this.page;
      let search = this.search
      this.loading = true;
      this.$_axios.get('classroom/list', {params: {page, ...search}}).then(res => {
        this.tableData = res.data.data
        let {headers} = res
        let date = this.$tools.getPaginationInfo(headers)
        this.total = date.total
        this.$store.commit("setPage",date.page);
      }).finally(() => this.loading = false);
		},
    tableHandle(row,handle){
      const { type } = handle
      if(type === "delete") {
        this.$confirm('删除后，会影响其他功能使用，是否删除？', {
            confirmButtonText: '删除',
            cancelButtonText: '点错了',
            type: 'warning'
          }).then(() => {
            classroomDel({ id:row.id }).then(res => {
              if (res.data.error.errorCode === 0) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                this.getData()
              } else {
                this.$message({
                  type: 'error',
                  message: '删除失败'
                })
              }
            })
          });
      } else if(type === "edit") {
        this.$router.push('./edit?id=' + row.id);
      }
    },

    onDownload() {
      tools.newFilesName('https://main.pro.honghueducation.com:8003/bf-static/school-system/preview/classroom.xlsx', '教室管理模版.xlsx')
    },
    onSearch(val) {
      this.$store.commit("setPage", 1);
      this.search = val
      this.getData()
    },
    onSuccess(res) {
      if (res.status === 0) {
        this.$message.success('导入完毕，请在企业微信中查收邮件。')
        this.dialogVisible = false
        this.$store.commit("setPage", 1);
        this.getData()
      } else this.$message.error(res.msg)
    },
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是xls、xlsx格式!',
          type: 'warning'
        })
      }
      return extension || extension2
    }
	},
	activated() {
		this.getData()
  },
	mounted() {
    this.$store.commit("setPage",1);
		this.getData()
    this.$_axios2.get('/api/common/base-school', {params: {usage: 2}}).then(res => {
      this.searchConfig[1].options = res.data.data.school
      this.searchConfig[2].options = res.data.data.building
    })
	}
}
</script>

<style lang="scss" scoped>
</style>
